@import 'colors.scss';


input:checked{
  background-color:$color-primario-verde !important;
}

input:focus, select:focus, textarea:focus {
  outline: 0 !important;
  border:1px solid transparent !important;
  box-shadow: 0 0 0 0.15rem rgba($color-primario-verde, 0.25) !important;
}


input {
  height: 40px;
  line-height: 40px !important;
  border-radius: 4px;
  border: 2px solid $color-lavado-gris-oscuro !important;
  outline: none !important;
  padding: 4px 4px 4px 16px !important;
}

input[type="file"] {
  border: none !important;
  display: none;    
}

input[type="checkbox"] {
  font-size: 18px;   
}

input::placeholder {
  overflow: visible;
}

textarea {
  resize: none;
  border-radius: 4px;
  border: 2px solid #D1DCDF !important;
}

select {
  height: 40px;
  border-radius: 4px;
  border: 2px solid #D1DCDF;
  outline: none;
  padding: 4px 4px 4px 16px;
  min-width: 130px;

  &:hover {
      cursor: pointer;
  }
}