// Colores primarios
$color-primario-verde: #006368;
$color-primario-azul: #008CA1;
$color-primario-negro: #1A2127;

// Colores secundarios
$color-secundario-morado: #812179;
$color-secundario-naranja: #FF6051;
$color-secundario-marron: #D7CDB6;
$color-secundario-verde: #00A774;
$color-secundario-verde-oscuro: #003546;
$color-secundario-marron-raro: #6F6974;

// Colores lavados
$color-lavado-gris: #F0F6F7;
$color-lavado-gris-oscuro: #D1DCDF;
$color-lavado-marron-oscuro: #BBB4B6;


// Color de texto e iconos
$color-texto-iconos: #495057;


// Color cancelar
$color-cancelar: #E41E1E;
$color-cancelar-hover: #820B0B;
